import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  ThemeProvider,
  createTheme,
  Box,
  Typography,
  LinearProgress,
  CssBaseline,
} from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { CheckAuth } from "./api/Auth";
import { protectedRouter, publicRouter } from "./router";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/ru";
import { ruRU } from "@mui/x-date-pickers/locales";

const theme = createTheme(
  {
    palette: {
      background: {
        default: "#fafafa",
      },
    },
    typography: {
      fontFamily: ["Rubik", "sans-serif"].join(","),
    },
  },
  ruRU
);

function App() {
  const { isAuth } = useSelector((state) => state.auth);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    CheckAuth(setAuthLoading);
  }, []);

  if (authLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <ToastContainer />
        <Typography variant="caption">Загрузка...</Typography>
        <LinearProgress variant="indeterminate" sx={{ width: 200 }} />
      </Box>
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={isAuth ? protectedRouter : publicRouter} />
        <ToastContainer />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
