import { toast } from "react-toastify";
import { run, requester, setIsAuth } from "../Utils";

export const Login = (setLoading, phone, password) => {
  run(setLoading, "Вход в аккаунт", async () => {
    const { data } = await requester.post("/auth/login", { phone, password });
    for (let key in data) localStorage.setItem(key, data[key]);
    setIsAuth(true);
  });
};

export const CheckAuth = (setLoading) => {
  run(setLoading, "Проверка авторизации", async () => {
    await requester.get("/auth/check");
    setIsAuth(true);
  });
};

export const SendSMS = (setLoading, phone, next) => {
  run(setLoading, "Отправка СМС кода", async () => {
    await requester.post("/auth/sendcode", { phone });
    next();
    toast.success("СМС код успешно отправлен на номер " + phone);
  });
};

export const Registration = (setLoading, phone, name, code, password, next) => {
  run(setLoading, "Регистрация нового аккаунта", async () => {
    await requester.post("/auth/registration", { phone, name, code, password });
    next();
    toast.success(
      "Вы были успешно зарегистрированы. Теперь войдите в свой новый аккаунт."
    );
  });
};

export const ResetPassword = (setLoading, phone, code, password, next) => {
  run(setLoading, "Сброс пароля", async () => {
    await requester.post("/auth/reset", { phone, code, password });
    next();
    toast.success(
      "Пароль успешно сменен. Пожалуйста, войдите в свой аккаунт с новыми данными."
    );
  });
};

export const Logout = (setLoading = () => {}, multisession) => {
  run(setLoading, "Выход из аккаунта", async () => {
    localStorage.removeItem("name");
    localStorage.removeItem("id");
    localStorage.removeItem("phone");
    setIsAuth(false);
    requester.delete("/auth/logout");
    if (multisession)
      toast.error(
        "Кто-то еще вошел в ваш аккаунт. Нельзя находится в сети с двух и более разных устройств."
      );
  });
};
